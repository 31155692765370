<template>
  <div>
    <Loader v-if="!services.services" />
    <div v-else class="wrap-services-section">
     <div class="services-list" v-if="services.services.services.length > 0">
       <div class="wrap-input">
         <input type="text" placeholder="Поиск по услугам" v-model="search">
         <svg v-if="search.length > 0" @click="clearInput()" width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
           <path d="M6.99486 7.00636C6.60433 7.39689 6.60433 8.03005 6.99486 8.42058L10.58 12.0057L6.99486 15.5909C6.60433 15.9814 6.60433 16.6146 6.99486 17.0051C7.38538 17.3956 8.01855 17.3956 8.40907 17.0051L11.9942 13.4199L15.5794 17.0051C15.9699 17.3956 16.6031 17.3956 16.9936 17.0051C17.3841 16.6146 17.3841 15.9814 16.9936 15.5909L13.4084 12.0057L16.9936 8.42059C17.3841 8.03007 17.3841 7.3969 16.9936 7.00638C16.603 6.61585 15.9699 6.61585 15.5794 7.00638L11.9942 10.5915L8.40907 7.00636C8.01855 6.61584 7.38538 6.61584 6.99486 7.00636Z" fill="#0F0F0F"/>
         </svg>
       </div>
       <Accordion v-for="(serItem, index) in services.services.category" :key="index" :data-cat="serItem.id" :acc="serItem">
         <div
             v-for="v in services.services.services"
             :key="v.id"
             :data-el="v.id"
         >
           <div
               class="list-date"
               v-bind:class="[activeItems.includes(v.id) ? 'active' : '']"
               v-if="serItem.id == v.category_id"
               @click="selectServices(v)">
             <div class="list-date__title">{{v.title}}</div>
             <div class="list-date__price">{{v.price_min.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}} ₽</div>
           </div>
         </div>
       </Accordion>
     </div>
     <div v-else>
       Нет подходящих услуг для записи. <br>
       Повторите с другими параметрами.
     </div>
     <div class="sel-btn btn-confirm" :class="[activeItems.length > 0 ? 'sel-btn--active' : '']" @click="setSelect()">Выбрать</div>
   </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from "@/components/Loader";
import Accordion from "@/components/Accordion";

export default {
  name: 'ServicesSection',
  data() {
    return {
      activeItems: [],
      isLoading: true,
      search: "",
    }
  },
  computed: {
    ...mapState(['companies', 'params', 'staffs', 'datetime', 'services'])
  },
  watch: {
    search(val) {
      if(val.length > 0) {
        document.querySelectorAll(".acc-title").forEach((acc) => {acc.classList.add("acc-heading")})
        document.querySelectorAll(".acc-info").forEach((accInfo) => {accInfo.style.height="auto"})
        document.querySelectorAll("[data-cat]").forEach((el) => {el.style.display = 'none'})
        document.querySelectorAll("[data-el]").forEach((el) => {el.style.display = 'none'})
        this.services.services.services.forEach((item) => {
          if(item.title.toLowerCase().includes(val)) {
            document.querySelector("[data-cat='"+ item.category_id + "']").style.display="block"
            document.querySelectorAll("[data-el='"+ item.id + "']").forEach((el) => {el.style.display="block"})
          }
        })
      }
      else {
        document.querySelectorAll(".acc-title").forEach((acc) => {acc.classList.remove("acc-heading")})
        document.querySelectorAll(".acc-info").forEach((acc) => {acc.style.height='0'})
        document.querySelectorAll("[data-cat]").forEach((el) => {
          el.style.display = 'block'
        })
        document.querySelectorAll("[data-el]").forEach((el) => {
          el.style.display = 'block'
        })
      }
    }
  },
  methods: {
    clearInput() {
      this.search = "";
    },
    setSelect() {
      let strServices = "";
      let activeItems =  this.activeItems
      this.services.services.services.forEach(function(el){
        if(activeItems.includes(el.id)) {
          if(strServices) {
            strServices += ", " + el.title
          }
          else {
            strServices = el.title
          }
        }
      })
      let curSection = 'StaffsSection'
      if(this.params.selDate && this.params.selStaff) {
        curSection = 'MainSection'
      }
      else if(this.params.selStaff) {
        curSection = 'DateTimeSection'
      }
      this.$store.dispatch('params/setSelServices', this.activeItems)
      this.$store.dispatch('params/setTitleBlockServices', strServices)
      this.$store.dispatch('params/setCurSection', curSection)
      this.$store.dispatch('params/setBackLink', 'ServicesSection')
    },
    selectServices(serv) {
      console.log(serv.id)
      if(this.activeItems.includes(serv.id)) {
        let index = this.activeItems.indexOf(serv.id);
        if (index >= 0) {
          this.activeItems.splice( index, 1 );
        }
      }
      else {
        this.activeItems.push(serv.id)
      }
      console.log(this.activeItems)
    },
    selectDate(modelData) {
      console.log(modelData)
      this.$store.dispatch('datetime/getTimes', {filialId: this.params.selCompany.id, staffId: this.params.selStaff.id, datetime: modelData})
      //this.heading = filialId;
      //this.$store.dispatch('params/setCurSection', 'MainSection')
      //this.$store.dispatch('params/setLoading', false)
      //this.$store.dispatch('params/setBackLink', 'CompaniesSection')
      //console.log(this.params.curSection)
    }
  },
  created() {
    let params = {
      filialId: this.params.selCompany.id
    };
    if(this.params.selStaff) {
      params.staffId = this.params.selStaff.id
    }
    if(this.params.selDate) {
      params.datetime = this.params.selDate + " " + this.params.selTime;
    }
    this.$store.dispatch('services/getServices', params)
  },
  components: {
    Loader,
    Accordion
  }
}
</script>

<style lang="scss" scoped>
.wrap-input svg {
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}
input[type='text'] {
  display: block;
  padding: 10px;
  width: 100%;
  margin-bottom: 15px;
  outline: none;
  border: 1px solid #fefefe;
  border-radius: 5px;
}
.list-date {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 5px;
  justify-content: space-between;
  &__title {
    text-align: left;
    padding-bottom: 5px;
  }
  &__price {
    //width: 75px;
    text-align: left;
    padding-top: 5px;
  }
}
.wrap-services-section {
  position: relative;

  .sel-btn {
    display: none;
    padding: 10px;
    text-align: center;
    position: sticky;
    right: 15px;
    left: 15px;
    bottom: 15px;
    background: #fefefe;
    border: 1px solid #0fd70f;
    border-radius: 8px;
    cursor: pointer;

    &:hover {

    }

    &--active {
      display: block;
    }
  }
}
.list-dates {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 15px;
}

.list-date {
  cursor: pointer;
  margin-top: 15px;
  padding: 5px 10px;
  text-align: center;
  background: white;
  border-radius: 8px;
  border: 1px solid white;

  &:hover {
    opacity: .8;
  }

  &__title {
    font-size: 17px;
    font-weight: 600;
  }

  &__address {
    font-size: 14px;
  }
}
.list-date.active {
  border: 1px solid #0fd70f;
}
</style>
