<template>
  <div>
    <div class="record-info">
      <div class="record-info__title">Специалист</div>
      <div class="record-info__value">{{params.selStaff.name}}</div>
    </div>
    <div class="record-info">
      <div class="record-info__title">Дата и время</div>
      <div class="record-info__value">{{params.selDate}} {{params.selTime}}</div>
    </div>
    <div class="record-info">
      <div class="record-info__title">Услуги</div>
      <div class="record-info__value">{{nameServices()}}</div>
    </div>
    <div class="form-record">
      <form action="">
      <input v-model="userName" required type="text" name="name" placeholder="Имя">
      <input v-model="userPhone" v-mask="'8 (###) ###-##-##'" required type="tel" name="phone" placeholder="Номер телефона">
      <div class="check-sogl">
        <div>
          <input v-model="userSogl" type="checkbox" name="sogl" id="" value="1">
        </div>
        <div>
          Я предоставляю <a href="#!" @click="processData">согласие на обработку персональных данных</a>, а также подтверждаю ознакомление и согласие с
          <a href="https://www.yclients.com/info/confidential" target="_blank">Политикой конфиденциальности</a>.
        </div>
      </div>
      </form>
      <div class="btn-confirm" @click="confirmRecord()">Подтвердить</div>
      <div v-if="error" class="error">{{error}}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {mask} from 'vue-the-mask'
export default {
  name: 'RecordSection',
  data() {
    return {
      userName: "",
      userPhone: "",
      userSogl: false,
      error: null,
    }
  },
  computed: {
    ...mapState(['companies', 'params', 'staffs', 'services', 'records']),
  },
  methods: {
    processData() {
      this.$store.dispatch('params/setCurSection', 'DataProcessing')
      this.$store.dispatch('params/setBackLink', 'RecordSection')
    },
    nameServices() {
      let strServices = "";
      let activeItems =  this.params.selServices
      this.services.services.services.forEach(function(el){
        if(activeItems.includes(el.id)) {
          if(strServices) {
            strServices += ", " + el.title
          }
          else {
            strServices = el.title
          }
        }
      })
      return strServices;
    },
    confirmRecord() {
      //console.log(this.userSogl)
      if(this.userName.length < 3 || this.userPhone.length < 9 || !this.userSogl) {
        this.error = "Заполните необходимые поля!"
        return;
      }
      else {
        this.error = null;
      }
      this.$store.dispatch('params/setUserName', this.userName)
      this.$store.dispatch('params/setUserPhone', this.userPhone.replace(/[\s()-]+/gi, ''))
      //this.$store.dispatch('records/sendSms', {filialId: this.params.selCompany.id, phone: this.params.userPhone})
      //this.$store.dispatch('params/setCurSection', 'SmsCodeSection')
      let info = {
        filialId: this.params.selCompany.id,
        staffId: this.params.selStaff.id,
        date: this.params.selDate,
        time: this.params.selTime,
        services: this.params.selServices,
        fullname: this.params.userName,
        phone: this.params.userPhone,
        //code: this.smsCode,
        utm: this.params.utm
      }
      this.$store.dispatch('records/setRecord', info)
      this.$store.dispatch('params/setBackLink', null)
      this.$store.dispatch('params/setCurSection', 'SuccessSection')
    },
  },
  created() {
    this.$store.dispatch('staffs/getStaffs', {filialId: this.params.selCompany})
    this.$store.dispatch('params/setBackLink', 'MainSection')
  },
  components: {

  },
  directives: {mask}
}
</script>

<style lang="scss" scoped>
.check-sogl {
  display: flex;
  text-align: left;
  font-size: 14px;

  a {
    text-decoration: none;
    color: #8e8e8e;
  }

  input[type=checkbox]
  {
    /* Double-sized Checkboxes */
    -ms-transform: scale(1.5); /* IE */
    -moz-transform: scale(1.5); /* FF */
    -webkit-transform: scale(1.5); /* Safari and Chrome */
    -o-transform: scale(1.5); /* Opera */
    transform: scale(1.5);
    margin-right: 10px;
  }
}
.error {
  color: red;
  font-size: 12px;
  margin-top: 5px ;
}
.record-info {
  text-align: left;
  margin-bottom: 20px;

  &__title {
    font-weight: 600;
    margin-bottom: 5px;
  }
}
.form-record {
  input[type=text], input[type=tel] {
    display: block;
    padding: 10px;
    width: 100%;
    margin-bottom: 15px;
    outline: none;
    border: 1px solid #fefefe;
    border-radius: 5px;
  }
  .btn-submit {
    border: 1px solid black;
    padding: 10px;
    cursor: pointer;

    &:hover {
      opacity: .9;
    }
  }
}
</style>
